import { CanActivateFn, Router } from '@angular/router';
import { inject } from "@angular/core";
import { Store } from "@ngrx/store";
import { ProfileSelectors, ProfileStoreState } from "../../core/store/profile";
import { map } from "rxjs/operators";

export const superuserGuard: CanActivateFn = (route, state) => {
    const router: Router = inject(Router);
    const profileStore = inject<Store<{ profile: ProfileStoreState }>>(Store);

    return profileStore.select(ProfileSelectors.selectProfile).pipe(map(({ profile }) => {
        if (profile?.role === 'SUPER_USER') {
            return true;
        }
        return router.createUrlTree(['/something-went-wrong']);
    }));
};
