import { Routes } from '@angular/router';
import {
  getDailyGraphDashboard,
  getDashboards, getMonthDashboard, getOperationsByUuid,
  getReferralsStatsByUuid,
  getRentalsByUuid,
  getReportsByUuid,
  getReportsStatsByUuid,
  getSubscriptionStatsByUuid,
  getTransactionByUuid,
  getTransactionStatsByUuid,
  getUserByUuid,
  searchReportsForAdmin,
  searchTransactionsForAdmin
} from "./resolvers";
import { superuserGuard } from "./partials/guards/superuser.guard";
import moment from "moment";

export const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('./pages/auth/auth.module')
  },
  {
    path: '',
    loadComponent: () => import('./layout/layout.component'),
    children: [
      {
        path: 'transactions',
        resolve: {
          data: searchTransactionsForAdmin
        },
        loadComponent: () => import('./pages/transactions/transactions.component')
      },
      {
        path: 'reports',
        resolve: {
          data: searchReportsForAdmin
        },
        loadComponent: () => import('./pages/reports/reports.component')
      },
      {
        path: 'merchants',
        resolve: {
          dashboardStats: getDashboards
        },
        loadComponent: () => import('./pages/merchants/merchants.component')
      },
      {
        path: 'merchant/:uuid',
        resolve: {
          userEntity: getUserByUuid,
          userReports: getReportsByUuid,
          userOperations: getOperationsByUuid,
          userTransactions: getTransactionByUuid,
          userRentals: getRentalsByUuid,
          userReportsStats: getReportsStatsByUuid,
          userTransactionsStats: getTransactionStatsByUuid,
          userReferralsStats: getReferralsStatsByUuid,
          userSubscriptionStats: getSubscriptionStatsByUuid
        },
        loadComponent: () => import('./pages/merchant/merchant.component')
      },
      {
        path: 'monitoring',
        resolve: {
          initFrom: () => moment().subtract(7, 'days').format(),
          initTo: () => moment().format(),
          initDailyGraphDashboard: getDailyGraphDashboard(moment().subtract(7, 'days').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')),
          iniMonthDashboard: getMonthDashboard(moment().subtract(7, 'days').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')),
        },
        canActivate: [superuserGuard],
        loadComponent: () => import('./pages/monitoring/monitoring.component')
      },
      {
        path: '404',
        loadComponent: () => import('./pages/not-found/not-found.component')
      },
      {
        path: 'something-went-wrong',
        loadComponent: () => import('./pages/something-went-wrong/something-went-wrong.component')
      },
      {
        path: '',
        pathMatch: 'full',
        loadComponent: () => import('./pages/start/start.component')
      }
    ]
  },
  {
    path: '**',
    redirectTo: '404'
  }
];
